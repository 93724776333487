<template>
  <div>
    <b-card
      title="HBL Detail"
    >
      <AgGridNoEdits
        ref="CSQORTHouseGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  name: 'CargoStatusORTHouse',
  components: {
    AgGridNoEdits,
  },
  props: {
    queryid: { type: String, default: '' },
    type: { type: String, default: '' },
  },
  data() {
    return {
      aggrid: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('CSQ.ORTHouse')
    this.aggrid.masterDetail = true
    this.aggrid.detailRowAutoHeight = true
    this.aggrid.columnDefs = [
      {
        headerName: 'HBL', field: 'hbl', minWidth: 175, cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'SCAC', field: 'scac', minWidth: 75,
      },
      {
        headerName: 'ISF',
        field: 'isfIndicator',
        minWidth: 75,
        cellRenderer: params => (params.value === 'Y' ? `<span style="color:green">${params.value}</span>` : `<span style="color:red">${params.value}</span>`),
      },
      {
        headerName: 'Status', field: 'latestStatus', minWidth: 75,
      },
      {
        headerName: 'MBL Qty',
        field: 'masterQty',
        minWidth: 125,
        cellRenderer: params => `<span>${params.value} ${params.data.masterUnit}</span>`,
      },
      {
        headerName: 'HBL Qty',
        field: 'manifestQty',
        minWidth: 125,
        cellRenderer: params => `<span>${params.value} ${params.data.unit}</span>`,
      },
      {
        headerName: 'Conveyance/Vessel', field: 'conveyanceName', minWidth: 150,
      },
      {
        headerName: 'Voyage', field: 'voyage', minWidth: 150,
      },
      {
        headerName: 'ETA', field: 'doa', minWidth: 140,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.CSQORTHouseGrid.saveState('CSQ.ORTHouse') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.CSQORTHouseGrid.saveState('CSQ.ORTHouse') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.CSQORTHouseGrid.saveState('CSQ.ORTHouse') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.CSQORTHouseGrid.saveState('CSQ.ORTHouse') }
    this.aggrid.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'Code', field: 'dispositionCode', minWidth: 100, maxWidth: 100,
          },
          { headerName: 'Disposition', field: 'disposition', minWidth: 185 },
          { headerName: 'Date', field: 'dispositionDate', minWidth: 150 },
          {
            headerName: 'Qty', field: 'qty', minWidth: 85, maxWidth: 105,
          },
          {
            headerName: 'Sequence', field: 'sequence', minWidth: 130, maxWidth: 130,
          },
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
          resizable: true,
        },
      },
      getDetailRowData(params) {
        params.successCallback(params.data.dispositions)
      },
    }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadMaster()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadMaster() {
      return axios.get(`/abi/query/cargostatus/${this.queryid}/O/false/disposition`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.CSQORTHouseGrid.ResetColumns()
        })
    },
    LoadCargoStatus() {
      this.LoadAll()
    },
  },
}
</script>
