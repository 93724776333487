<template>
  <div>
    <b-card
      title="HAWB Detail"
    >
      <AgGridNoEdits
        ref="CSQAirHouseGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  name: 'CargoStatusAirHouse',
  components: {
    AgGridNoEdits,
  },
  props: {
    queryid: { type: String, default: '' },
    type: { type: String, default: '' },
  },
  data() {
    return {
      aggrid: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('CSQ.AirHouse')
    this.aggrid.masterDetail = true
    this.aggrid.detailRowAutoHeight = true
    this.aggrid.columnDefs = [
      {
        headerName: 'HAWB', field: 'hawb', minWidth: 165, cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: 'Status', field: 'latestStatusCode', minWidth: 75,
      },
      {
        headerName: 'House Part', field: 'housePart', minWidth: 65,
      },
      {
        headerName: 'Master Part', field: 'masterPart', minWidth: 65,
      },
      {
        headerName: 'In-Bond #', field: 'inBondNumber', minWidth: 125,
      },
      {
        headerName: 'Master Boarded/Manifest Qty',
        field: 'masterManifestQty',
        minWidth: 125,
        cellRenderer: params => `<span>${params.data.masterBoardedQty}/${params.value}</span>`,
      },
      {
        headerName: 'House Boarded/Manifest Qty',
        field: 'houseManifestQty',
        minWidth: 125,
        cellRenderer: params => `<span>${params.data.houseBoardedQty}/${params.value}</span>`,
      },
      {
        headerName: 'Carrier', field: 'importingCarrier', minWidth: 95,
      },
      {
        headerName: 'Flight #', field: 'flightNumber', minWidth: 95,
      },
      {
        headerName: 'ETA', field: 'doa', minWidth: 140,
      },
      {
        headerName: 'Port of Unlading', field: 'actualPOU', minWidth: 150,
      },
    ]
    this.aggrid.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'Code', field: 'dispositionCode', minWidth: 115, maxWidth: 115,
          },
          { headerName: 'Disposition', field: 'disposition', minWidth: 175 },
          {
            headerName: 'Date', field: 'dispositionDate', minWidth: 150, maxWidth: 150,
          },
          {
            headerName: 'In-Bond/Entry #', field: 'identifier', minWidth: 155, maxWidth: 155,
          },
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
          resizable: true,
        },
      },
      getDetailRowData(params) {
        params.successCallback(params.data.dispositions)
      },
    }
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.CSQAirHouseGrid.saveState('CSQ.AirHouse') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.CSQAirHouseGrid.saveState('CSQ.AirHouse') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.CSQAirHouseGrid.saveState('CSQ.AirHouse') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.CSQAirHouseGrid.saveState('CSQ.AirHouse') }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadMaster()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadMaster() {
      return axios.get(`/abi/query/cargostatus/${this.queryid}/A/false/disposition`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.CSQAirHouseGrid.ResetColumns()
        })
    },
    LoadCargoStatus() {
      this.LoadAll()
    },
  },
}
</script>
